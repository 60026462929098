<template>
	<div style="display: flex; flex-direction: row;">
			<div style="height: 100%; width:20% ; display: flex; flex-direction: column;align-items: center;">
				<div style="height: 20%; padding: 10px;">
					<icon-svg name="scrtime" style="color:#5cd9e8;width: 20px; height: 30px;margin-bottom: -8px"></icon-svg>
					<span style="font-size: 23px;font-family: '300-CAI978'">{{time}}</span>
					</div>
				<dv-decoration-8 style="width:500px;height:50px;margin-top: -10px" :color="['#3f7ff5', '#633cf1']" />
			</div>
		<dv-decoration-1 style="width:200px;height:50px;" />
		<div style="width:40%;">
			<h2 style="margin:0 0 0 -220px;font-size: 30px;cursor: pointer" @click="$router.push({ name: 'recommend' })">服务型制造共性技术公共服务平台</h2>
			<dv-decoration-5 style="width:80%;height:60%; margin:10px 0 0 60px;" :color="['#5e69ff', '#827fec']" />
		</div>
		<dv-decoration-1 style="width:200px;height:50px;" />
		<div style="height: 100%; width:20% ; display: flex; flex-direction: column;align-items: center;">
			<div style="height: 20%; padding: 10px;">
				<span style="font-size: 20px">{{date}}</span>
				<span style="margin-left: 10px;">{{week}}</span></div>
			<dv-decoration-8 :reverse="true" style="width:500px;height: 50px;margin-top: -10px" :color="['#3f7ff5', '#633cf1']" />
		</div>

		<!-- <dv-decoration-8 :reverse="true" style="width:20%;height:50%;" :color="['#3f7ff5', '#633cf1']" /> -->

	</div>
</template>

<script>
	import {
		getDate,
		getWeekDate,
		getTime
	} from './datetime.js'
	export default{
		data(){
			return{
				time: '',
				date: '',
				week: '',
			}
		},
		mounted() {
			this.setNowTime()
		},
		methods: {
			setNowTime() {
				this.date = getTime()
				this.week=getWeekDate()
				setInterval(() => {
					this.time = getDate()
				}, 1000)
			}
		}
	}
</script>

<style scoped lang="scss" >
	span{
		font-size: 19px;
		font-weight: 800;
		color: #5cd9e8;
	}
	h2 {
		transform: translate(35%);
	}
</style>
