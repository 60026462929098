<template>
  <!--    <div style="width: 100%; height:100%">-->
  <!--&lt;!&ndash;      <div id="earth"/>&ndash;&gt;-->
  <!--      <div class="map">-->
  <!--        <div class="map1"><img src="../../../../../assets/img/tech-analysis/lbx.png" width="100%"></div>-->
  <!--        <div class="map2"><img src="../../../../../assets/img/tech-analysis/jt.png" width="100%"></div>-->
  <!--        <div class="map3"><img src="../../../../../assets/img/tech-analysis/map.png" width="100%"></div>-->
  <!--        <div class="map4" id="map_1"></div>-->
  <!--      </div>-->
  <!--    </div>-->
    <div class="hi100" style="padding-bottom: 10vh">
        <div class="r1-center hi100 uf uf-ae uf-pc pr">
            <img class="dipan" src="../../../../../assets/img/analysis/dipan.png" alt=""/>
            <div class="t-box uf uf-pa wi100">
                <div class="total-li uf uf-ver uf-pc uf-ac pr">
                    <div class="num">{{ viewNum || 0 }}</div>
                    <img class="bg" src="../../../../../assets/img/analysis/img03.png" alt=""/>
                    <div class="tit">访问量</div>
                </div>
                <div class="total-li uf uf-ver uf-pc uf-ac pr">
                    <div class="num">{{ serviceNum || 0 }}</div>
                    <img class="bg" src="../../../../../assets/img/analysis/img03.png" alt=""/>
                    <div class="tit">服务项总数</div>
                </div>
                <div class="total-li uf uf-ver uf-pc uf-ac pr">
                    <div class="num">{{ orderNum || 0 }}</div>
                    <img class="bg" src="../../../../../assets/img/analysis/img03.png" alt=""/>
                    <div class="tit">订单成交量</div>
                </div>
                <div class="total-li uf uf-ver uf-pc uf-ac pr">
                    <div class="num">{{ userNum || 0 }}</div>
                    <img class="bg" src="../../../../../assets/img/analysis/img03.png" alt=""/>
                    <div class="tit">注册用户数</div>
                </div>
                <div class="total-li uf uf-ver uf-pc uf-ac pr">
                    <div class="num">{{ userNum }}</div>
                    <img class="bg" src="../../../../../assets/img/analysis/img03.png" alt=""/>
                    <div class="tit">组合服务数</div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// import * as echarts from 'echarts/core'
// import 'echarts-gl'

// import { CanvasRenderer } from 'echarts/renderers'
// import { GlobeComponent } from 'echarts-gl/components'
export default {
    name: 'earth-center',
    data() {
        let bgImage = require('../../../../../assets/img/tech-analysis/pageBg1.png')
        let baseTexture = require('../../../../../assets/img/tech-analysis/earth2.jpg')
        return {
            serviceNum: 785,
            viewNum: 875201,
            orderNum: 12413,
            userNum: 9870,
            option: {
                // backgroundColor: '#000',
                globe: {
                    baseTexture: baseTexture,
                    shading: 'lambert',
                    environment: bgImage,
                    atmosphere: {
                        show: false
                    },
                    light: {
                        ambient: {
                            intensity: 0.4
                        },
                        main: {
                            intensity: 1.5
                        },
                        width: 300,
                        height: 300
                    },
                },
                series: []
            }
        }
    },
    created() {
    },
    mounted() {
        this.initCommonParamNum()
    },
    methods: {
// 公共数量
        initCommonParamNum() {
            this.$http({
                url: this.$http.adornUrl(this.$api.QCC.SEARCH.COMMON),
                method: 'GET',
                params: this.$http.adornParams({})
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.serviceNum = data.data.serviceNum
                    this.viewNum = data.data.viewNum
                    this.orderNum = data.data.orderNum
                    this.userNum = data.data.userNum
                    if (data.data.serviceInfoList) {
                        this.components[0].name = data.data.serviceInfoList[0].serviceName
                        this.components[0].id = data.data.serviceInfoList[0].id
                        this.components[0].company = data.data.serviceInfoList[0].enterpriseName
                        this.components[1].name = data.data.serviceInfoList[0].serviceName
                        this.components[1].id = data.data.serviceInfoList[0].id
                        this.components[1].company = data.data.serviceInfoList[0].enterpriseName
                        this.components[2].name = data.data.serviceInfoList[0].serviceName
                        this.components[2].id = data.data.serviceInfoList[0].id
                        this.components[2].company = data.data.serviceInfoList[0].enterpriseName
                        this.components[3].name = data.data.serviceInfoList[0].serviceName
                        this.components[3].id = data.data.serviceInfoList[0].id
                        this.components[3].company = data.data.serviceInfoList[0].enterpriseName
                        this.components[4].name = data.data.serviceInfoList[0].serviceName
                        this.components[4].id = data.data.serviceInfoList[0].id
                        this.components[4].company = data.data.serviceInfoList[0].enterpriseName
                        this.components[5].name = data.data.serviceInfoList[0].serviceName
                        this.components[5].id = data.data.serviceInfoList[0].id
                        this.components[5].company = data.data.serviceInfoList[0].enterpriseName
                    }
                }
            })
        },
    }
}
</script>

<style scoped>
table {
    border-collapse: collapse;
    border-spacing: 0;
    width: calc(100% - 1.8vw);
    margin: 0 auto;
}

table th, table td {
    border: none;
    border-bottom: 1px solid rgba(35, 124, 117, 0.5);
    line-height: 1.8vw;
    padding: 0 5px;
    font-size: 13px;
    /*text-align: center;*/
}

table thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

table tbody {
    display: block;
    width: 100%;
    height: calc(100% - 2.4vw);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

table tbody::-webkit-scrollbar {
    display: none;
}

table th {
    color: rgba(141, 225, 245, 0.8);
    font-weight: normal;
    text-align: left;
}

table td {
    color: #f2ffff;
}

table tr:nth-child(even) {
    background: rgba(141, 225, 245, 0.1);
}

.analysis-1 {
    width: 100vw;
    height: 100vh;
    /*background: url("@/assets/img/bg2.jpg");*/
    background: url("../../../../../assets/img/analysis/bg-jy.jpg") no-repeat center;
    background-size: cover;
    overflow-y: auto;
}

.head-tit {
    position: relative;
    width: 96%;
    margin: 0 auto;
    height: 82px;
    text-align: center;
    padding-top: 22px;
    /*color: #00ffda;*/
    background: url("../../../../../assets/img/analysis/tit.png");
    background-size: contain;
}

.head-tit .tit {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 40%;
    text-align: center;
    font-size: 2.4vw;
    font-family: '华文行楷';
    background-image: -webkit-linear-gradient(bottom, #149be9, #2ffdff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

.tit-box {
    background: linear-gradient(to right, rgba(0, 88, 131, 0.8), rgba(0, 88, 131, 0));
    color: #c5faff;
    line-height: 42px;
    padding-left: 10px;
    font-size: 16px;
}

.tit-box.d {
    background: linear-gradient(to right, rgba(0, 88, 131, 0.8), rgba(0, 88, 131, 0.2), rgba(0, 88, 131, 0));
}

.t-box {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0 14px;
    z-index: 2;
}

.total-li:nth-child(1) {
    margin-top: 15%;
}

.total-li:nth-child(2) {
    margin-bottom: 25%;
    margin-right: 15%;
}

.total-li:nth-child(3) {
    margin-bottom: 50%;
}

.total-li:nth-child(4) {
    margin-bottom: 25%;
    margin-left: 15%;
}

.total-li:nth-child(5) {
    margin-top: 15%;
}

.total-li .num {
    position: relative;
    color: #c5faff;
    font-size: 2.3vw;
    font-family: '300-CAI978';
    margin-bottom: 10px;
    z-index: 3;
}

.total-li .bg {
    width: 6vw;
}

.total-li .tit {
    width: 100%;
    background: rgba(3, 144, 211, 0.8);
    padding: 3px 6px;
    text-align: center;
    color: #c5faff;
    margin-top: -10px;
}

.un-li {
    padding: 1.5vw;
    width: 50%;
    height: 50%;
}

.un-li .num {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6vw;
    margin-bottom: -40%;
    width: 6vw;
    height: 6vw;
    /*line-height: 6vw;*/
    position: relative;
    font-family: '300-CAI978'
}

.un-li .num .num-main {
    margin-left: 0.8vw;
    display: flex;
    align-items: flex-end;
    line-height: 1;
}

.un-li .num .num-label {
    font-size: 1.6vw;
}

.un-li .num .num-tips {
    font-size: 0.5vw !important;
    margin-bottom: 0.1vw;
}

.un-li .num:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("../../../../../assets/img/analysis/img02.png");
    background-size: cover;
    opacity: .9;
    animation: ani1 linear 3s infinite;
}

@keyframes ani1 {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

.un-li:nth-child(1) .num {
    color: #ffc600;
}

.un-li:nth-child(2) .num {
    color: #bf00ff;
}

.un-li:nth-child(3) .num {
    color: #ff9900;
}

.un-li:nth-child(4) .num {
    color: #ff0000;
}

.color-1 {
    color: #67C23A !important;
}

.color-2 {
    color: #E6A23C !important;
}

.color-3 {
    color: #E6A23C !important;
}

.color-4 {
    color: #F56C6C !important;
}

.un-li img {
    width: 86%;
}

.un-li .tit {
    font-size: 14px;
    color: #00e4ff;
    margin-top: -20%;
}

div ::v-deep .dv-border-box-8 .border-box-content {
    padding: 3px 0 0 3px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
}

.chart-wrap {
    padding: 2vh 2vw;
    z-index: 2;
    overflow: hidden;
    box-sizing: border-box;
}

.row1 {
    height: 60%;
    overflow: hidden;
}

.r1-left, .r1-right {
    width: 25%;
    height: 100%;
}

.r1-center .dipan {
    width: 70%;
    margin-bottom: -3vh;
}

.jl {
    position: relative;
}

.jl:before {
    content: '';
    position: absolute;
    display: block;
    left: 2px;
    bottom: 2px;
    width: 16px;
    height: 16px;
    border: 2px solid #88b6ff;
    border-right: none;
    border-top: none;
    z-index: 4;
}

.jl:after {
    content: '';
    position: absolute;
    display: block;
    right: 2px;
    bottom: 2px;
    width: 16px;
    height: 16px;
    border: 2px solid #88b6ff;
    border-left: none;
    border-top: none;
    z-index: 4;
}

.r2-left, .r2-right {
    width: 49%;
    height: 100%;
}

div ::v-deep .header .header-item {
    font-size: 13px;
}

.date {
    position: absolute;
    right: 30px;
    top: 10px;
    font-size: 16px;
    color: #ccf2ff;
}

.btn {
    font-size: 12px;
    color: #99f5ff;
    padding: 0 20px;
    line-height: 28px;
    border-radius: 16px;
    border: 1px solid transparent;
    margin-left: 15px;
    cursor: pointer;
}

.btn:hover {
    color: #528b91;
}

.btn.on:hover {
    color: #99f5ff;
}

.btn.on {
    border: 1px solid #7cccd5;
}

#lineChart {
    width: calc(100% - 1vw);
    height: calc(100% - 2vh);
}
</style>

<style scoped lang="scss">
.row2 {
  overflow: hidden;
}

::v-deep .de-box {
  overflow: hidden;

  .el-table {
    background-color: transparent !important;
    border-right: none !important;

    &:before {
      //去除底部白线
      left: 0;
      bottom: 0;
      width: 100%;
      height: 0px;
    }

    .gutter {
      width: 0 !important;
    }

    .el-table__body {
      margin-right: 0 !important;
    }

    .el-table__expanded-cell {
      background-color: transparent !important;
    }

    th {
      background-color: transparent !important;
      border-bottom: 1px solid rgba(35, 124, 117, 0.5);
      color: rgba(141, 225, 245, 0.8);
    }

    tr {
      background-color: transparent !important;
    }

    .cell {
      background-color: transparent;
      color: rgba(141, 225, 245, 0.8);
      border: none;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .el-table__cell {
      background-color: transparent !important;
      border-bottom: 1px solid rgba(35, 124, 117, 0.5) !important;
    }

    .el-table__row--striped {
      .el-table__cell {
        background-color: rgba(141, 225, 245, 0.1) !important;
      }
    }
  }

  //.el-table__body-wrapper::-webkit-scrollbar {
  //  /*width: 0;宽度为0隐藏*/
  //  width: 0;
  //}
  // 滚动条的宽度
  .el-table__body-wrapper::-webkit-scrollbar {
    width: 6px; // 横向滚动条
    height: 6px; // 纵向滚动条 必写
    background-color: rgba(141, 225, 245, 0.1) !important;
  }

  // 滚动条的滑块
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(141, 225, 245, 0.3) !important;
    border-radius: 3px;
  }
}

::v-deep .pagination {
  align-items: center;
  line-height: 1;

  .btn-prev, .btn-next, .number {
    background-color: transparent !important;
  }

  .number {
    color: rgba(255, 255, 255, 0.3) !important;
  }

  button, .active {
    color: #c5faff !important;
  }

  button:disabled {
    color: rgba(255, 255, 255, 0.3) !important;
  }
}

::v-deep .cascader-box {
  position: absolute;
  left: 0;
  top: 82px;
  right: 0;
  margin: 0 auto;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  .el-cascader-panel {
    position: relative;
    background-color: #fff !important;
    z-index: 99;
  }

  .cascader-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
  }
}
</style>
