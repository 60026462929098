<template>
    <div id="index">
        <div class="bg">
            <dv-loading v-if="loading">Loading...</dv-loading>
            <div v-else class="host-body">
                <div class="header">
                    <screen-header></screen-header>
                </div>
                <div class="content">
                    <el-row style="height: 93%;">
                        <el-col style="height: 100%;">
                            <el-row style="height: 100%; ">
                                <!-- 上半部分 -->
                                <el-col style="height:100%; display: flex;flex-direction: row;">
                                    <div class="left"
                                         style=" height: 100%;flex: 2; flex-direction: column; align-items: stretch;">
                                        <dv-border-box-1 style="height: 35%;">
                                            <left-first :pos_percent="Number(pos_percent)"
                                                        :neg_percent="Number(neg_percent)"
                                                        v-if="pos_percent"></left-first>
                                        </dv-border-box-1>
                                        <!--                                        <dv-border-box-12 style="height: 30%;">-->
                                        <!--                                            <left-second :linexKey="linexKey" :linePos="linePos" :lineNeg="lineNeg" v-if="linexKey.length>0"></left-second>-->
                                        <!--                                        </dv-border-box-12>-->
                                        <dv-border-box-8 style="height: 65%;">
                                            <right-second :config="config" v-if="config.data.length>0"></right-second>
                                        </dv-border-box-8>
                                    </div>
                                    <div style="height: 100%; flex: 3.5; flex-direction: column; align-items: stretch; ">
                                        <div style="height: 100%;">
                                            <earth-center style="height:95%"/>
                                            <!--                                            <center2 style="height: 20%;margin-top: -160px" :recommendLabel="companyLabels" v-if="companyLabels.length>0"></center2>-->
                                        </div>
                                    </div>
                                    <div class="left"
                                         style="height: 100%; flex: 2; flex-direction: column; align-items: stretch;">
                                        <dv-border-box-8 style="height: 40%;">
                                            <left-third :histogramData="histogramData" :histogramLabel="histogramLabel" v-if="histogramLabel.length>0"></left-third>
                                        </dv-border-box-8>
                                        <!--                                        <dv-border-box-12 style="height: 30%;">-->
                                        <!--                                            <right-second :config="config" v-if="config.data.length>0"></right-second>-->
                                        <!--                                        </dv-border-box-12>-->
                                        <dv-border-box-12 style="height: 60%;">
                                            <right-third :recommendList="sbrzServiceList" v-if="sbrzServiceList.length>0"></right-third>
                                        </dv-border-box-12>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ScreenHeader from './components/screen-header.vue'
import EarthCenter from './components/earth-center'
import LeftFirst from './components/left-first.vue'
// import LeftSecond from './components/left-second'
import LeftThird from './components/left-third'
// import RightFirst from './components/right-first.vue'
import RightSecond from './components/right-second'
import RightThird from './components/right-third'
// import Center2 from './components/center2'
import {checkIsNull, random, sortJson} from '../../../../utils'

export default {
    components: {
        // Center2,
        EarthCenter,
        LeftFirst,
        // LeftSecond,
        LeftThird,
        // RightFirst,
        RightSecond,
        RightThird,
        ScreenHeader
        // TopologyInfo
    },
    mounted() {
        this.getNearest()
    },
    data() {
        return {
            loading: true,
            pos_percent: 0,
            neg_percent: 0,
            linexKey: [],
            linePos: [],
            lineNeg: [],
            histogramLabel: [],
            histogramData: [],
            config: {
                data: []
            },
            enterpriseId: '',
            enterpriseName: '',
            mobile: '',
            recommendService: '',
            mainId: '',
            userInfoList: {},
            corporatePortraitList: [],
            sbrzServiceList: [{
                'id': '0eb298b236f3447fb4903c0c84aedc5b',
                'serviceNo': 'SP2108090738',
                'serviceName': '热泵热水器',
                'itemCode': '20010102',
                'itemName': '家电安全',
                'enterpriseName': '中认英泰检测技术有限公司',
                'itemDesc': '智能网联消费品',
                'minPrice': 54,
                'maxPrice': 540
                },
                {
                    'id': 'dbe06e65a5ff4585a399b49420f1f220',
                    'serviceNo': 'SP2108090041',
                    'serviceName': '电熨斗',
                    'itemCode': '20010103',
                    'itemName': '电磁兼容',
                    'enterpriseName': '中认英泰检测技术有限公司',
                    'itemDesc': '智能网联消费品',
                    'minPrice': 900,
                    'maxPrice': 4230
                },
                {
                    'id': '6696d52c4597450d8b62e2f37ad17c28',
                    'serviceNo': 'SP2108090063',
                    'serviceName': '吸油烟机',
                    'itemCode': '20010102',
                    'itemName': '家电安全',
                    'enterpriseName': '中认英泰检测技术有限公司',
                    'itemDesc': '智能网联消费品',
                    'minPrice': 9,
                    'maxPrice': 29500
                },
                {
                    'id': '7390da2c22164e24bb2cdcb3ad5b3b04',
                    'serviceNo': 'SP2108090019',
                    'serviceName': '室内加热器',
                    'itemCode': '20010102',
                    'itemName': '家电安全',
                    'enterpriseName': '中认英泰检测技术有限公司',
                    'itemDesc': '智能网联消费品',
                    'minPrice': 9,
                    'maxPrice': 29500
                },
                {
                    'id': '2e788c5495714769b1880f15efc5e0cc',
                    'serviceNo': 'SP2108090028',
                    'serviceName': '通用',
                    'itemCode': '20010103',
                    'itemName': '电磁兼容',
                    'enterpriseName': '中认英泰检测技术有限公司',
                    'itemDesc': '智能网联消费品',
                    'minPrice': 1000,
                    'maxPrice': 100
                },
                {
                    'id': '2e788c5495714769b1880f15efc5e0cc',
                    'serviceNo': 'SP2108090028',
                    'serviceName': '家电检测',
                    'itemCode': '20010103',
                    'itemName': '电磁兼容',
                    'enterpriseName': '中认英泰检测技术有限公司',
                    'itemDesc': '智能网联消费品',
                    'minPrice': 2000,
                    'maxPrice': 10000
                },
                {
                    'id': '2e788c5495714769b1880f15efc5e0cc',
                    'serviceNo': 'SP2108090028',
                    'serviceName': '家电检测',
                    'itemCode': '20010103',
                    'itemName': '电磁兼容',
                    'enterpriseName': '中认英泰检测技术有限公司',
                    'itemDesc': '智能网联消费品',
                    'minPrice': 2000,
                    'maxPrice': 10000
                }],
            companyLabels: ['专利较多（例）', '软著较多（例）', '商标较少（例）', '微博初级（例）']
        }
    },
    methods: {
        // 获取数据列表
        getNearest() {
            this.$http({
                url: this.$http.adornUrl(this.$api.TECH.WORD2VEC.NEAREST),
                method: 'get',
                params: this.$http.adornParams({})
            }).then(({data}) => {
                if (data && data.code === 0) {
                    if (checkIsNull(data.data)) {
                        this.enterpriseName = '汉唐信通（北京）咨询有限公司（样例）'
                        this.pos_percent = 80
                        this.neg_percent = 20
                        // this.negWords = result.neg_words
                        this.histogramLabel = ['1月', '2月', '3月', '4月', '5月', '6月']
                        this.histogramData = ['164', '256', '230', '421', '588', '536']
                        let pos = {
                            '2021-06（样例）': 200,
                            '2021-07（样例）': 300,
                            '2021-08（样例）': 200,
                            '2021-09（样例）': 400,
                            '2021-10（样例）': 500,
                            '2021-11（样例）': 900
                        }
                        let neg = {
                            '2021-06（样例）': 200,
                            '2021-07（样例）': 300,
                            '2021-08（样例）': 200,
                            '2021-09（样例）': 400,
                            '2021-10（样例）': 500,
                            '2021-11（样例）': 900
                        }
                        for (var key in neg) {
                            this.linexKey.push(key)
                        }
                        this.linexKey.forEach(item => {
                            this.linePos.push(pos[item])
                            this.lineNeg.push(neg[item])
                        })

                        let word = []
                        let neg_word = [
                            {'name': '生物医药行业', 'value': 1446},
                            {'name': '机械装备行业', 'value': 928},
                            {'name': '汽车行业', 'value': 906},
                            {'name': '厨电行业', 'value': 825},
                            {'name': '纺织行业', 'value': 514},
                            {'name': '包装行业', 'value': 486},
                            {'name': '家具行业', 'value': 53},
                            {'name': '生鲜食品行业', 'value': 513},
                            {'name': '电梯行业', 'value': 93},
                            {'name': '精密模具行业', 'value': 50}]

                        neg_word.forEach((item, index) => {
                            if (index > 6) {
                                return
                            } else {
                                word.push(item)
                            }
                        })

                        this.config = {
                            data: neg_word,
                            showValue: true
                        }
                        this.loading = false
                    } else {
                        this.enterpriseId = data.data.id
                        this.enterpriseName = data.data.enterpriseName
                        this.mobile = data.data.mobile
                        let result = JSON.parse(data.data.result)
                        let all_count = result.pos_attr_count + result.neg_attr_count
                        this.pos_percent = (result.pos_attr_count / all_count * 100).toFixed(2)
                        this.neg_percent = (result.neg_attr_count / all_count * 100).toFixed(2)
                        // this.negWords = result.neg_words

                        eval(result.word_percent).forEach((item, index) => {
                            if (index < 7) {
                                this.histogramLabel.push(item.groupId)
                                this.histogramData.push(item.value)
                            }
                        })

                        let pos = sortJson(eval('(' + result.pos_months + ')'))
                        let neg = sortJson(eval('(' + result.neg_months + ')'))
                        for (var key in neg) {
                            this.linexKey.push(key)
                        }
                        this.linexKey.forEach(item => {
                            this.linePos.push(pos[item])
                            this.lineNeg.push(neg[item])
                        })

                        let word = []
                        eval(result.neg_words).forEach((item, index) => {
                            if (index > 6) {
                                return
                            } else {
                                word.push(item)
                            }
                        })

                        this.config = {
                            data: word,
                            showValue: true
                        }

                        this.getResult()
                    }


                }

            })
        },
        getResult() {
            this.$http({
                url: this.$http.adornUrl(this.$api.RECOMMENDED.SUBMIT),
                method: 'POST',
                params: this.$http.adornParams({
                    mobile: this.mobile,
                    companyName: this.enterpriseName,
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    if (data.dataMap !== undefined) {
                        this.recommendService = data.dataMap.recommendService
                        this.mainId = data.dataMap.recommend.id
                        this.userInfoList = data.dataMap.userInfoList
                        this.getStats()
                    }
                }
            })
        },
        // 推荐算法 进程
        getStats() {
            this.$http({
                url: this.$http.adornUrl(this.$api.RECOMMENDED.STATS),
                method: 'post',
                params: this.$http.adornParams({
                    mobile: this.mobile
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    let mobile = this.mobile
                    let type = data[mobile]
                    if (type === 'CHECK_ING') {
                        this.getStats()
                    } else {
                        this.toResult()
                    }
                } else {
                    this.$message.error(data.msg)
                }
            })
        },
        // 推荐算法弹框
        toResult() {
            let list = [
                this.enterpriseName,
                this.mobile,
                this.mainId,
                this.enterpriseId
            ]
            this.corporatePortraitList = list
            this.getList()
        },
        // 获取企业画像接口
        getList() {
            let dataList = this.corporatePortraitList
            // if(dataList.length!== 0){
            //    dataList =  dataList.split(',')
            //
            // }else{
            //   dataList = []
            // }
            this.$http({
                url: this.$http.adornUrl(this.$api.RECOMMENDED.LIST),
                method: 'post',
                params: this.$http.adornParams({
                    mobile: dataList.length > 0 ? dataList[1] : null,
                    mainId: dataList.length > 0 ? dataList[2] : null,
                    enterpriseId: dataList.length > 0 ? dataList[3] : null,
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    let enterpriseLabel = data.dataList.enterpriseLabel.slice(0, 4)
                    let recommendService = data.dataList.recommendService
                    this.sbrzServiceList = recommendService
                    this.sbrzServiceList.forEach(item => {
                        if (checkIsNull(item.minPrice)) {
                            let min = item.skuList[0].price
                            item.skuList.forEach(sku => {
                                if (sku.price < min) {
                                    min = sku.price
                                }
                            })
                            item.minPrice = min
                        }
                    })
                    this.sbrzServiceList.forEach(item => {
                        item.id = item.serviceId
                    })
                    if (enterpriseLabel.length > 0) {
                        this.companyLabels = []
                        enterpriseLabel.forEach((item, index) => {
                            if (index < 4) {
                                this.companyLabels.push(item.labelName)
                            }
                        })
                    }
                    this.loading = false
                } else {
                    this.$message.error(data.msg)
                    this.loading = false
                }
            })
        }
    }
}
</script>

<style scoped>
#index {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
}

.bg {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.2rem 0.2rem 0 0.2rem;
    background-image: url("../../../../assets/img/tech-analysis/bg-jy.jpg");
    background-size: cover;
    background-position: center center;
    color: white;
}

.host-body {
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
}

.header {
    width: 100%;
    height: 100px;
    flex: 2;
    background-color: transparent
}

.content {
    flex: 2;
    width: 100%;
    height: calc(100% - 80px);
}

.systime {
    color: #5cd9e8;
    font-size: 16px !important;
    font-weight: 800;
    margin-left: 10px;
}
</style>
