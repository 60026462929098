<template>
    <div class="cputop">
        <div class="topTitle uf uf-ac uf-pc mt-2">
            <icon-svg name="invstu" style="color:#5cd9e8;font-size: 20px"></icon-svg>
            <span class="fs-xl text mx-2" style="font-size: 16px;">服务数量统计</span>
        </div>
        <div id="LeftChart" style="height:100%"  ></div>
    </div>
</template>

<script>
    const echarts = require('echarts')
    export default {
        props: {
            histogramData: {
                type: Array,
                required: true,
                default: null
            },
            histogramLabel: {
                type: Array,
                required: true,
                default: null
            }
        },
        data() {
            return {
                state : {
                    dataList: [],
                    dataArray: [],
                    dataArray2: [],
                },
                color1: ['#FF3333', '#FF7744', '#FFCC22', '#33FF33', '#33CCFF', '#7744FF', '#E93EFF'],
                color2: ['#FF8888', '#FFA488', '#FFDD55', '#66FF66', '#77DDFF', '#9F88FF', '#E38EFF'],
                color3: ['#FFCCCC', '#FFC8B4', '#FFEE99', '#99FF99', '#CCEEFF', '#CCBBFF', '#F0BBFF'],
                aar: [],
                aar2: [],
                barWidth: 30
            }
        },
        mounted() {
            this.draw()
        },
        methods: {
            draw() {
                this.state.dataList = this.histogramData

                this.state.dataList.map((item, index) => {
                    let obj = {
                        value: item,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0,
                                1,
                                0,
                                0,
                                [
                                    {
                                        offset: 0,
                                        color: this.color1[index], // 0% 处的颜色
                                    },
                                    {
                                        offset: 0.6,
                                        color: this.color2[index], // 60% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: this.color3[index], // 100% 处的颜色
                                    },
                                ],
                                false
                            ),
                        },
                    }
                    this.aar.push(Object.assign({}, obj))
                })
                this.state.dataList.map((item, index) => {
                    let obj = {
                        value: item,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0,
                                1,
                                0,
                                0,
                                [
                                    {
                                        offset: 0,
                                        color: this.color1[index], // 0% 处的颜色
                                    },
                                    {
                                        offset: 0.6,
                                        color: this.color2[index], // 60% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: this.color3[index], // 100% 处的颜色
                                    },
                                ],
                                false
                            ),
                            borderWidth: 1,
                            borderColor: this.color1[index],
                            shadowColor: 'rgba(0, 0, 0, 0.2)',
                            shadowBlur: 1,
                        },
                    }
                    this.aar2.push(Object.assign({}, obj))
                })
                this.state.dataArray = this.aar
                this.state.dataArray2 = this.aar2
                // 基于准备好的dom，初始化echarts实例
                this.chart = echarts.init(document.getElementById('LeftChart'))
                //  ----------------------------------------------------------------
                let option = {
                    xAxis: {
                        type: 'category',
                        data: this.histogramLabel,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#fff',
                            },
                        },
                        axisLabel: {
                            show: true,
                            margin: 25,
                            rotate: 40,
                            color: 'white',
                        },
                        axisTick: {
                            show: true,
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: '#fff',
                            },
                        },
                        axisLabel: {
                            show: true,
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: 'rgba(255,255,255,0.2)',
                                width: 2,
                            },
                        },
                    },
                    tooltip: {
                        show: true,
                        orient: 'vertical',
                        formatter: '{b0}: {c0}',
                    },
                    series: [
                        {
                            type: 'bar',
                            barWidth: this.barWidth,
                            z: 1,
                            data: this.state.dataArray,
                        },
                        {
                            z: 3,
                            type: 'pictorialBar',
                            symbolPosition: 'start',
                            data: this.state.dataArray,
                            symbol: 'diamond',
                            symbolOffset: [0, '50%'],
                            symbolSize: [this.barWidth, 10],
                            silent: true,
                        },
                        {
                            z: 3,
                            type: 'pictorialBar',
                            symbolPosition: 'end',
                            data: this.state.dataArray,
                            symbol: 'diamond',
                            symbolOffset: [0, '-50%'],
                            symbolSize: [this.barWidth, this.barWidth * 0.3],
                            silent: true,
                        },
                    ],
                }

                this.chart.setOption(option)

            }
        },
        destroyed() {
            window.onresize = null
        }
    }
</script>

<style lang="scss" scoped>
    .cputop{
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: center;
        .topTitle{
            height: 10%;
            padding: 10px;
        }
        #LeftChart{
            flex: 2;
        }
    }
    .newTech {
        font-size: 14px;
        margin-left: 85px;
        color: #00ffd0;
        cursor: pointer;
    }
</style>
