<template>
    <div class="stratop">
        <div class="topTitle uf uf-ac uf-pc mt-2">
            <icon-svg name="product" style="color:#5cd9e8;font-size: 20px"></icon-svg>
            <span class="fs-xl text mx-2" style="font-size: 16px;">热门组合推荐</span>
        </div>
        <dv-scroll-board class="board" :config="config" style="width:23vw;height:40vh;margin-left: 30px;margin-top: 20px;color: #65f0ff" />
    </div>
</template>

<script>
    export default {
        props: {
            recommendList: {
                required: true,
                type: Array,
                default: null
            }
        },
        data() {
            return {
                config: ''
            }
        },
        created () {
            this.recommendData()
        },
        methods: {
            recommendData() {
                let list = []
                this.recommendList.forEach(item => {
                    let subList = []
                    subList.push(item.serviceName)
                    subList.push(item.itemName)
                    subList.push(item.enterpriseName)
                    subList.push(item.minPrice)
                    list.push(subList)
                })

                this.config = {
                    header: ['产品名称', '所属类别', '服务商', '最低价格（/元）'],
                    data: list,
                    index: true,
                    columnWidth: [20, 90, 100, 90],
                    headerBGC: '#0a1f2d', //表头
                    oddRowBGC: '#0f1325', //奇数行
                    evenRowBGC: '#171c33', //偶数行
                    headerHeight: 50

                }
            }
        }

    }
</script>

<style scoped lang="scss">
    .stratop{
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: center;
        justify-content: center;
    .topTitle{
        height: 10%;
        padding: 10px;
    }
    #rightFirst{
        flex: 2;
    }
    }
    ::v-deep  .board .header {
        min-width: 10px;
        padding: 0 0 0 0;
        text-align: center;
    }
    ::v-deep .dv-scroll-board .header-item {
        font-weight: bold;
        color: #c9f9ff;
    }

</style>
